.home-page-welcome-note-container {
  padding-top: 96px;
  padding-left: 126px;
}
.home-work-page-container {
  margin: 160px 0;

  .home-page-work-cards {
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
    border-radius: 2px;
    box-shadow: 1px 1px 1px inherit;

    .project-source {
      padding: 60px 80px;
    }
    &.padded-image-source {
      .image-source {
        padding: 60px !important;
      }
    }
  }
}
@media (max-width: 991.98px) {
  .home-page-welcome-note-container {
    padding-top: 25px;
    padding-left: 0px;
  }
  .home-work-page-container {
    margin-top: 20px;
  }
}
