.social-media-icon {
  margin-right: 42px;
  display: inline-block;
}
.social-media-icon svg {
  font-size: 1.96rem;
  transition: 320ms;
  transition-timing-function: linear;
  -webkit-transition: 120ms;
  -webkit-transition-timing-function: linear;
  color: #c20a75;
}
.social-media-icon svg:hover {
  transition: 320ms;
  transform: translateY(-3px);
  opacity: 0.6;
}

@media (max-width: 991.98px) {
  .social-media-icons-container {
    position: relative;
    margin-top: 57px;
  }

  .social-media-icon {
    margin: 0px 10px;
  }
}
