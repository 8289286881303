.navbar {
  padding: 2% 0;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .navbar-brand {
    color: #5500ff;

    img {
      width: 14%;
    }
  }
  .nav-link {
    font-size: 18px;
    line-height: 1.6em;
    letter-spacing: 0.02rem;
    color: #5500ff;
    transition: 0.2s;
    font-weight: 700;
  }
  .nav-link:hover, .navbar-brand:hover {
    transition: 0.2s;
    opacity: 0.76;
  }
}

@media (max-width: 991.98px) {
  .navbar {
    text-align: center;
    padding-left: 0;

    .nav-link {
      margin-left: 0px !important;
    }
  }
}
