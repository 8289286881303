.password-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  padding: 146px 0px;

  .form-control {
    height: 48px;
    border: 2px solid #000;
    border-radius: 0px;
  }

  .input-error {
    border: 2px solid #bf1717 !important;
  }
}

@media (max-width: 991.98px) {
  .password-container {
    width: 100% !important;
  }
}