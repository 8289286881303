.btn, .btn-group {
  order: none;
  border-radius: 2px;
  padding: 12px 24px;
  color: #ffffff;
  background-color: #5500ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
  transition: all .2s linear;
  font-weight: 700;
}
.btn:focus,.btn:active:focus,.btn.active:focus,
.btn.focus,.btn:active.focus,.btn.active.focus,
.btn:hover {
  outline: #5500ff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
  color: #ffffff;
  background-color: #6511ff;
  transition: all .2s linear;
}
.btn.btn-default {
  background-color: rgba(251, 249, 253, 1);
}
@media (max-width: 991.98px) {
  .btn, .btn-group {
    margin-bottom: 15px;
  }
}
.btn-nav-link {
  background: none;
  box-shadow: none;
  border-radius: 4px;
  padding: 12px 22px;
}
.btn-nav-link:hover, .btn-nav-link:active, .btn-nav-link:focus {
  background: none !important;
  transform: translateY(-3px);
  color: #010101;
}
