$card-default-border-radius: 2px;

.article-container {
  margin-top: 30px;

  .card-img-top {
    height: 280px;
    object-fit: cover;
    padding: 0;
    border-top-right-radius: $card-default-border-radius;
    border-top-left-radius: $card-default-border-radius;
  }
}
