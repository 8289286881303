.work-page-container {
  margin-top: 12%;

  .work-landing-page {
    img {
      padding: 56px;
    }
    &.full-bleed img {
      padding: 0px;
    }
  }
  .work-page-presnetation {
    margin-top: 50px;

    .work-tab {
      margin-top: 56px;
      margin-bottom: 112px;
    }

    .work-tab:last-child {
      margin-bottom: 100px;
    }

    .table-competitors {
      td {
        width: 33.33%
      }
    }
    .table-user-persona {
      td {
        width: 33.33%
      }
      ul {
        width: 99.10%;
        margin-left: -34px;
        list-style-type: "-";
        li {
          padding-left: 6px;
          padding-bottom: 12px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .work-page-container {
      .work-page-presnetation {
        padding: 10px;

        .work-tab {
          margin-bottom: 0px;

          .col-sm-12.col-md-7 {
            padding-top: 16px;
          }
        }
      }
    }
  }
}