$brand-default-color: #5500ff;
$brand-dark-color: #010101;
$brand-white: #fefefe;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  font-size: 14px;
  scroll-behavior: smooth;
}
body {
  font-family: 'Work Sans', sans-serif;
  background-color: $brand-white;
  color: $brand-dark-color;
}

.font-style-merriweather {
  font-family: 'Merriweather', serif;
}
main {
  margin-bottom: 80px;
}
h1 {
  font-size: calc((3.2 - 1) * 1.2vw + 1rem);
  font-family: 'Merriweather', serif;
}
h5 {
  font-size: calc((3.2 - 1) * 0.2vw + 1rem);
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  font-style: normal;
  letter-spacing: .036em;
  line-height: 1.46;
  text-transform: none;
  white-space: pre-wrap;
}
table tr, table td, p, ul, ol li, span {
  font-size: calc((3.2 - 1) * 0.16vw + 1rem);
  font-weight: 300;
}
b, .bold {
  font-weight: 700;
}
a {
  font-size: 1.2rem;
  color: $brand-default-color;
  transition: 0.6s;
  font-weight: 600;
}
a:hover {
  color: #6511ff;
  transition: 0.6s;
}
.next-link {
  margin-top: 96px;
}
footer {
  width: 100%;
  bottom: 0;
  padding: 80px 0px;
}
ul, ol {
  li {
    margin: 12px 0;
  }
}
@media (max-width: 991.98px) {
  .lead-xlg {
    font-size: 2.4rem;
  }
  .lead-lg {
    font-size: 1.2rem;
  }
  .lead {
    font-size: 0.72rem;
  }
  span, p {
    font-size: 0.68rem;
  }
}

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color: #000;
  left: auto;
  top: auto;
  width: 20%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid #000000;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

.img-circular {
  border-radius: 50%;
}

.not-found {
  padding: 120px 0px;
  h1 {
    font-size: 64px;
    padding-bottom: 16px;
  }
  a {
    font-size: 18px;
  }
}

.about-section {
  padding-top: 120px;
}

// check Bootstrap reset file
@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 1600px) and (min-device-width: 1200px), screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 1600px) and (min-device-width: 1200px) and (min-resolution: 192dpi) {
  body {
    text-align: left !important;
  }
}

@media (max-width: 991.98px) {
  body, p, a, ul, ol, table {
    font-size: 16px !important;
  }
}