$card-bg-color: #ffffff;
$transition-time: 0.2s;
$card-default-border-radius: 2px;

.card {
  background-color: $card-bg-color;
  border-radius: $card-default-border-radius;
  border: none;
  cursor: pointer;
  transition: all $transition-time linear;

  .card-header, .card-footer {
    background-color: transparent;
    border-radius: $card-default-border-radius;
  }
  .card-header {
    border-bottom: none;
    padding: 1rem 2rem;
  }
  .card-body {
    padding: 0rem 2rem;
  }
  .card-img-top {
    height: 182px;
    padding: 1.86rem;
    transition: $transition-time ease-in;
    filter: grayscale(70%);
  }
  .card-footer {
    border-top: none;
    margin-top: 2rem;
    padding: 0;

    a {
      display: inline-block;
      width: 50%;
      border-top: 1px solid rgba(0, 0, 0, .08);;
      padding: 12px 0px;
      text-align: center;
    }
    a:first-child {
      border-bottom-left-radius: $card-default-border-radius;
    }
    a:last-child {
      border-bottom-right-radius: $card-default-border-radius;
    }
  }
  .card-footer.single-link {
    a {
      display: inline-block;
      width: 100%;
      border-bottom-left-radius: $card-default-border-radius;
      border-bottom-right-radius: $card-default-border-radius;
    }
  }
}

.card:hover {
  transform: translateY(-6px);

  .card-img-top {
    transition: $transition-time linear;
    filter: grayscale(10%);
  }
}
